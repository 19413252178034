import { filteredGetBuilder } from "./response";

interface CountryDto {
  id: number;
  country: string;
}

export const list = filteredGetBuilder<CountryDto>(`backend/countries`);

export default {
  list,
}