import { filteredGetBuilder } from "./response";
export interface LegalDto {
  id: number;
  contact_id: number;
  capital: number | null;
  currency_id: number;
  country_id: number;
  type_id: number;
  tax_number: string | null;
  activity_id: number;
  legal_id: number | null;
  rna: string | null;
  date_start: string | null;
  date_end: string | null;
  country_name: string;
  type_code: string;
  type_name: string;
  currency_name: string;
  activity_code: string;
  activity_name: string;
}

export const list = filteredGetBuilder<LegalDto>(`backend/legals`);

export const contactLegal = (contactId: number) => filteredGetBuilder<LegalDto>(`backend/legals`)({ contact_id: contactId }, { itemsPerPage: 30, page: 1, sortBy: ["u.name"], sortDesc: [false] });

export default {
  list, contactLegal,
}