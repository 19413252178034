import { filteredGetBuilder } from "./response";

export interface SiteReviewDto {
  id?: number;
  user_id?: number;
  order_id?: number;
  nom?: string | null;
  email?: string | null;
  description?: string;
  statut?: number | boolean;
  date?: string | Date;
  user_name?: string;
}

export const list = filteredGetBuilder<SiteReviewDto>(`backend/sitereviews`);
