import { PageState } from '@/store/page-state';
import { DocTypeEnum } from '@/types/doc';
import { stringify } from 'qs';
import useSWRV, { IConfig } from 'swrv';
import { Ref } from 'vue';
import { apiPdg } from './api-pdg';
import { ContactMetaKeyEnum } from './api-pdg/contact-meta';
import { ContactDto } from './api-pdg/contacts';

function generateKey(pageState: Ref<PageState | undefined>) {
  return stringify(pageState.value, {
    sort: (a, b) => a.localeCompare(b),
    skipNulls: true,
  });
}

export function useFiles(folder: string | number, key: string | number) {
  return useSWRV(`files/${folder}/${key}`, () => apiPdg.files(folder, key));
}

export function useMedias(pageState: Ref<PageState>) {
  return useSWRV(() => !pageState.value ? undefined : `medias/${JSON.stringify(pageState.value)}`, () => apiPdg.medias(pageState.value.filters, pageState.value.options));
}

export function useMedia(id: number | string) {
  return useSWRV(() => `medias/${id}`, () => apiPdg.media(id));
}

export function useJournals(pageState: Ref<PageState>) {
  return useSWRV(() => !pageState.value ? undefined : `journals/${JSON.stringify(pageState.value)}`, () => apiPdg.journals(pageState.value.filters, pageState.value.options));
}

export function useRequestQuote(id: number, config?: IConfig) {
  return useSWRV(() => `requestquotes/${id}`, () => apiPdg.requestQuotes.get(id), config);
}

export function useRequestQuoteShipping(id: number, config?: IConfig) {
  return useSWRV(() => `requestquotes/${id}/shipping`, () => apiPdg.requestQuotes.shipping(id), config);
}

export function useRequestQuotes(pageState: Ref<PageState>, config?: IConfig) {
  return useSWRV(() => !pageState.value ? undefined : `requestquotes/${JSON.stringify(pageState.value)}`, () => apiPdg.requestQuotes.list(pageState.value.filters, pageState.value.options), config);
}

export function useContacts(pageState: Ref<PageState>) {
  // const fetcher = debounce((filters, options) => apiPdg.contacts(filters, options), 500);
  return useSWRV(() => !pageState.value ? undefined : `contacts/${JSON.stringify(pageState.value)}`, () => apiPdg.contacts.list(pageState.value.filters, pageState.value.options));
}

export function useContact(id: number, config?: IConfig) {
  return useSWRV(() => `contacts/${id}`, () => apiPdg.contacts.get(id), config);
}

export function useContactSalesRep() {
  return useSWRV(`contacts/salesRep`, () => apiPdg.contactSalesRep(), { dedupingInterval: 60000 });
}

export function useContactDispute(contactId: number) {
  return useSWRV(() => `contactmetas/${contactId}/dispute`, () => apiPdg.contactMetaForContact(contactId, ContactMetaKeyEnum.DISPUTE));
}

export function useContactMetaKeys() {
  return useSWRV(() => `contactmetas/keys`, () => apiPdg.contactMetaKeys());
}

export function useContactMetaForKey(contactId: Ref<number | null | undefined>, key: string) {
  return useSWRV(() => !contactId.value || !key ? undefined : `contact/${contactId.value}/metas/${key}`, () => apiPdg.contactMetaForContact(contactId.value!, key));
}

export function useProduct(productId: number | string) {
  return useSWRV(() => `product/${productId}`, () => apiPdg.product(productId));
}

export function useProducts(pageState: Ref<PageState | undefined>, q: { categories: boolean } = { categories: false }) {
  return useSWRV(
    () => !pageState.value ? undefined : `products/${generateKey(pageState)}?${JSON.stringify(q ?? {})}`,
    () => apiPdg.products(pageState.value!.filters, pageState.value!.options, q),
    { revalidateOnFocus: false });
}

export function useProductReviews(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `product-reviews/${JSON.stringify(pageState.value)}`, () => apiPdg.productReviews.list(pageState.value!.filters, pageState.value!.options));
}

export function useSiteReviews(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `site-reviews/${JSON.stringify(pageState.value)}`, () => apiPdg.siteReviews.list(pageState.value!.filters, pageState.value!.options));
}

export function useTransactionsVatReport(yearMonth: Ref<string>) {
  return useSWRV(() => yearMonth.value && `transaction/vat-report/${yearMonth.value}`, () => apiPdg.transactionsVatReport(yearMonth.value));
}

export function useTasks(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `tasks/${JSON.stringify(pageState.value)}`, () => apiPdg.tasks.list(pageState.value!.filters, pageState.value!.options));
}

export function useMeTasksCounter(user: Ref<ContactDto | undefined>) {
  return useSWRV(() => !user.value ? undefined : `me/tasks-counter`, () => apiPdg.meTasksCounter());
}

export function useShipments(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `shipments/${JSON.stringify(pageState.value)}`, () => apiPdg.shipments.list(pageState.value!.filters, pageState.value!.options));
}

export function useShipmentTracking(carrier: string, shipmentNumber: string) {
  return useSWRV(`shipments/tracking/${carrier}/${shipmentNumber}`, () => apiPdg.shipments.tracking(carrier, shipmentNumber), { dedupingInterval: 60000 * 5 });
}

export function useDocs(pageState: Ref<PageState | undefined>, type: DocTypeEnum) {
  return useSWRV(() => !pageState.value ? undefined : `docs/${type}/${JSON.stringify(pageState.value)}`, () => apiPdg.docs(pageState.value!.filters, pageState.value!.options, { type }));
}

export function useConditionnements(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `conditionnements/${JSON.stringify(pageState.value)}`, () => apiPdg.conditionnements(pageState.value!.filters, pageState.value!.options));
}

export function useNewsletterSubscriptionsForContact(contactId: Ref<number | null | undefined>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !contactId.value ? undefined : `newsletters/${contactId.value}`, async () => {
    const contactDto = await apiPdg.contacts.get(contactId.value!);
    if (!contactDto.user) {
      return undefined;
    }
    return (await apiPdg.newsletterSubscriptionsForEmail(contactDto.user.username!))?.data?.find(item => item.email === contactDto.user.username!);
  });

  return {
    data,
    isValidating,
    error,
    mutate,
  }
}

export function usePurchaseDue(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `purchase/due/${JSON.stringify(pageState.value)}`, () => apiPdg.purchaseDue(pageState.value!.filters, pageState.value!.options));
}

export function useSalesStats() {
  return useSWRV(`sales/stats`, apiPdg.salesStats);
}

export function useParams(pageState: Ref<PageState | undefined>, displayOutput: boolean = false) {
  return useSWRV(
    () => !pageState.value ? undefined : `params/${JSON.stringify(pageState.value)}?displayOuput=${displayOutput}`,
    () => apiPdg.params(pageState.value!.filters, pageState.value!.options, { output: displayOutput ? 'select' : '' }),
    { revalidateOnFocus: false });
}

export function useAccounts(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `accounts/${JSON.stringify(pageState.value)}`, () => apiPdg.accounts.list(pageState.value!.filters, pageState.value!.options));
}

export function useCategories(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `categories/${JSON.stringify(pageState.value)}`, () => apiPdg.categories.list(pageState.value!.filters, pageState.value!.options));
}

export function usePayments(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `payments/${JSON.stringify(pageState.value)}`, () => apiPdg.payments.list(pageState.value!.filters, pageState.value!.options));
}

export function useCurrencies(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `currencies/${JSON.stringify(pageState.value)}`, () => apiPdg.currencies.list(pageState.value!.filters, pageState.value!.options));
}

export function useBusinessActivities(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `business-activities/${JSON.stringify(pageState.value)}`, () => apiPdg.businessActivities.list(pageState.value!.filters, pageState.value!.options));
}

export function useCountries(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `countries/${JSON.stringify(pageState.value)}`, () => apiPdg.countries.list(pageState.value!.filters, pageState.value!.options));
}

export function useOrganizationTypes(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `organization-types/${JSON.stringify(pageState.value)}`, () => apiPdg.organizationTypes.list(pageState.value!.filters, pageState.value!.options));
}

export function useLegalForContact(contactId: Ref<number | undefined>) {
  return useSWRV(() => !contactId.value ? undefined : `legals/contact-${contactId.value}`, () => apiPdg.legals.contactLegal(contactId.value!));
}