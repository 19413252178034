import { list as listAccounts } from './accounts';
import { list as listBusinessActivities } from './business-activities';
import { list as listCategories } from './categories';
import { conditionnements } from './conditionnements';
import { contactMetaForContact, contactMetaKeys, contactMetas, contactMetasForContact } from './contact-meta';
import { contact, contactSalesRep, contacts } from './contacts';
import countries from './countries';
import organizationTypes from './organization-types';
import { list as listCurrencies } from './currencies';
import { add as addDocOverride, update as updateDocOverride } from './doc-overrides';
import { docShipmentLines, docs, listPurchaseDue } from './docs';
import { emailtemplates, emailtemplatesRender } from './email-templates';
import { files } from './files';
import { journals } from './journals';
import { meTasksCounter } from './me';
import { media, medias } from './medias';
import { newsletterSubscriptions, newsletterSubscriptionsForEmail } from './newsletter-subscriptions';
import { params } from './params';
import { list as listPayments } from './payments';
import { list as listProductReviews } from './product-reviews';
import { product, products } from './products';
import { get as getRequestQuote, shipping as getRequestQuoteShipping, list as listRequestQuotes } from './request-quotes';
import { get as getSalesStats } from './sales-stats';
import { list as listShipments, remove as removeShipment, tracking } from './shipments';
import { list as listSiteReviews } from './site-reviews';
import { add, get, list } from './tasks';
import { transactionsVatReport } from './transactions';
import { vatRates } from './vat-rates';
import legals from './legals';

export type { Error, Response } from './response';

export const apiPdg = {
  accounts: {
    list: listAccounts,
  },
  categories: {
    list: listCategories,
  },
  currencies: {
    list: listCurrencies,
  },
  businessActivities: {
    list: listBusinessActivities,
  },
  legals,
  countries,
  organizationTypes,
  files,
  medias,
  media,
  journals,
  emailtemplates,
  emailtemplatesRender,
  contacts: {
    list: contacts,
    get: contact,
  },
  contactSalesRep,
  products,
  product,
  contactMetas,
  contactMetasForContact,
  contactMetaForContact,
  contactMetaKeys,
  transactionsVatReport,
  vatRates,
  requestQuotes: {
    list: listRequestQuotes,
    get: getRequestQuote,
    shipping: getRequestQuoteShipping,
  },
  docShipmentLines,
  tasks: {
    list: list,
    get: get,
    add: add,
  },
  docOverrides: {
    add: addDocOverride,
    update: updateDocOverride,
  },
  productReviews: {
    list: listProductReviews,
  },
  siteReviews: {
    list: listSiteReviews,
  },
  salesStats: getSalesStats,
  meTasksCounter,
  shipments: {
    tracking: tracking,
    list: listShipments,
    remove: removeShipment,
  },
  payments: {
    list: listPayments,
  },
  docs,
  purchaseDue: listPurchaseDue,
  conditionnements,
  newsletterSubscriptions,
  newsletterSubscriptionsForEmail,
  params,
}