
import { filteredGetBuilder } from './response';

export interface AccountDto {
  id: number,
  editable: 0 | 1 | boolean,
  status: 0 | 1 | boolean,
  label: string | null
  info?: string | null,
}

export const list = filteredGetBuilder<AccountDto>(`backend/accounts`);
