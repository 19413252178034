import { elementGet } from "./response";

export interface SalesStatsDto {
  day: number,
  month: number,
  ytd: number,
  daySales: number,
  monthSales: number,
  ytdSales: number,
  year: number,
  dayAverage: number,
  monthAverage: number,
  yearSales: number,
  daySalesAverage: number,
  monthSalesAverage: number,
}

export const get = async () => elementGet<SalesStatsDto>(`backend/sales/stats`);