
import { filteredGetBuilder } from './response';

export interface OrganizationTypeDto {
  id: number;
  country_id: number | null;
  code: string;
  label: string;
}

export const list = filteredGetBuilder<OrganizationTypeDto>(`backend/organizationtypes`);

export default {
  list,
}